<script>
import {differenceInHours, parseISO} from "date-fns"
import {UTooltip} from "uloc-vue"

export default {
  name: "FaturaDetails",
  props: {
    fatura: {required: true},
    isCompra: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    }
  },
  components: {
    UTooltip
  }
}
</script>

<template>
  <div class="f-right">
    <div class="fat-id">{{ String(fatura.id).padStart(6, '0') }}</div>
    <div class="fat-desc">{{fatura.descricao}}</div>
    <div class="fat-id">{{ fatura.transacaoId }}</div>
    <div class="fat-price">
      <div class="title">Valor a pagar</div>
      <div class="val">R${{fatura.valor|moeda}}</div>
    </div>
    <div class="fat-id assinatura" v-if="fatura.assinatura">Assinatura</div>
    <div class="fat-due">
      <div class="val" v-if="atraso(fatura.dataVencimento) > 0"><span class="text-negative">Venceu em</span> {{fatura.dataVencimento|formatDate('dd/MM/yyyy')}}</div>
      <div class="val" v-else>Vence em {{fatura.dataVencimento|formatDate('dd/MM/yyyy')}}</div>
    </div>
    <div v-if="isCompra" class="compra-segura">
      <i class="fa fa-lock"></i> Compra segura
      <u-tooltip :offset="[10, 10]">Levamos a sério a proteção de suas informações pessoais. Para garantir que os detalhes da sua conta estejam a salvo, esta transação é criptografada e segura.</u-tooltip>
    </div>
  </div>
</template>
