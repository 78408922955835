<template>
  <div class="novo-cc box-v2">
    <div class="title">Novo cartão de crédito</div>
    <form class="form" @submit.prevent="submit">
      <e-row mr>
        <e-col>
          <erp-s-field label="Número do cartão">
            <erp-input autocomplete="cc-number" autofocus v-mask="'#### #### #### ####'" class="sl-input" v-model="cartao.numero" required />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 100px">
          <erp-s-field label="Validade">
            <erp-input autocomplete="cc-exp" class="sl-input" v-mask="'##/##'" placeholder="MM/AA" v-model="cartao.validade" required />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 80px">
          <erp-s-field label="CVV">
            <erp-input autocomplete="cc-csv" class="sl-input" v-model="cartao.cvv" required />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Nome igual no cartão">
            <erp-input id="name" autocomplete="cc-name" class="sl-input" v-model="cartao.nome" required />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Endereço de cobrança">
            <erp-input id="address" autocomplete="cc-address" class="sl-input" v-model="cartao.endereco" required />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Cidade">
            <erp-input id="city" autocomplete="cc-city" class="sl-input" v-model="cartao.cidade" required />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Estado/Região" class="e-input-modern">
            <uf-select v-model="cartao.uf" full-name required />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="CEP">
            <erp-input id="zip" type="number" autocomplete="cc-zip" class="sl-input" v-model="cartao.cep" required />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="País/Região">
            <erp-input class="sl-input" v-model="cartao.pais" disable />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="CPF/CNPJ">
            <erp-input id="document" autocomplete="cc-document" class="sl-input" v-model="cartao.documento" required />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Número de telefone (opcional)">
            <erp-input id="phone" autocomplete="cc-phone" class="sl-input" v-model="cartao.telefone" />
          </erp-s-field>
        </e-col>
      </e-row>
      <div class="opts2">
        <u-btn type="submit" :loading="salvando" class="sl-btn" no-caps color="green" label="Salvar novo cartão" />
        <span class="compra-segura m-l text-grey non-selectable">
          <i class="fa fa-lock"></i> Compra segura
          <u-tooltip :offset="[10, 10]">Levamos a sério a proteção de suas informações pessoais. Não armazenamos dados como número do cartão e código de segurança. Uma vez digitados, enviamos para o banco e recebemos um token para que possamos utilizar nas próximas transações sem precisar dos dados sigilosos.</u-tooltip>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import UfSelect from "@/components/pessoa/components/include/UfSelect"
import {validateCardNumber} from "@/utils/validators"
import {cadastraCartao} from "@/domain/business/services"

export default {
  name: "NovoCartaoCredito",
  components: {
    UfSelect,
    ErpInput,
    ErpSField,
    ECol,
    ERow
  },
  props: {
    pessoa: {required: true}
  },
  data () {
    return {
      salvando: false,
      cartao: {
        numero: null,
        validade: null,
        cvv: null,
        nome: null,
        endereco: null,
        cidade: null,
        uf: null,
        cep: null,
        pais: 'Brasil',
        documento: null,
        telefone: null
      }
    }
  },
  methods: {
    submit () {
      if (!validateCardNumber(this.cartao.numero)) {
        this.dg('Cartão inválido',  'Clique em Ok para continuar', 'Ok', 'c-error')
        return
      }
      this.salvando = true
      cadastraCartao(this.cartao)
          .then(response => {
            console.log(response.data)
            this.salvando = false
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Novo cartão salvo com sucesso.'})
            this.$emit('cartaoSalvo', response.data)
          })
          .catch(error => {
            this.alertApiError(error)
            this.salvando = false
          })
    },
  }
}
</script>
